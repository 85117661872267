import {
  SET_STUDENT_MAP_DATA,
  FILTER_STUDENT_MAP_DATA,
  SET_TABLE_DATA,
  UPDATE_OPTION_SPECIFIC_MEASURES,
  UPDATE_GEO_LEVEL,
  UPDATE_YEAR,
  UPDATE_TYPE_MEASURE,
  UPDATE_SPECIFIC_MEASURE,
  UPDATE_SECTOR,
  SET_PROGRESS_BAR
} from "../actions/types";

const initialState = {
  loading: false,
  colors: [],
  legend_keys: [],
  data: null,
  statsState: null,
  statsNation: null,
  tableData: [],
  tableDisplayData: {displayData: [], selectedArea: "", selectedState: ""},
  tableDisplayFilters: {selectedTypeMeasure: '',
                        selectedSpecificMeasure: '', 
                        selectedSector: ''},
  optionTypeMeasures: [],
  selectedTypeMeasure:"",
  typeMeasuresVSSpecificMeasures: {},
  optionSpecificMeasures: [],
  selectedSpecificMeasure:"",
  optionGeoLevels: [],
  selectedGeoLevel: "",
  originalOptionGeoLevels: [],
  optionYears: [],
  originalOptionYears: [],
  selectedYear: "",
  optionSectors: [],
  originalOptionSectors: [],
  selectedSector: ""
};

function filterTableByArea(area, selectedState, tableDataOriginal, statsState, statsNation) {
  let result = {};
  let displayData = [];
  let state_feature; 
  // console.log(area);
  // console.log(selectedState);
  for (var i=0; i<statsState.length; i++) {
    let feature = statsState[i];
    if (feature.region_name === selectedState) {
      state_feature = feature;
    }
  }; 

  for (var k=0; k<tableDataOriginal.length; k++) {
      let feature = tableDataOriginal[k];
      if (feature.region_name === area) {
        displayData.push({
          var_name: "Total", 
          area_count:feature.counts === "no_data" ? "no data" : feature.counts,
          state_count: state_feature.counts,
          nation_count: statsNation.counts
        });
        break; 
      }
    };
  result.displayData = displayData;
  result.selectedArea = area;
  result.selectedState = selectedState;
  return result;
}

function updateSpecificMeasures(
  selectedTypeMeasure,
  typeMeasuresVSSpecificMeasures
) {
  if (selectedTypeMeasure === null){
    return [];
  }else{
    return typeMeasuresVSSpecificMeasures[selectedTypeMeasure];
  };
}


function updateFilters(_specificMeasure, _state){
  if (_specificMeasure.includes("Hotspots")){
    return Object.assign({}, _state, {
      selectedSpecificMeasure: _specificMeasure,
      optionYears: [],
      optionGeoLevels: [],
      selectedYear:"",
      selectedGeoLevel:""
    });
  }else{
    return Object.assign({}, _state, {
      selectedSpecificMeasure: _specificMeasure,
      optionGeoLevels: _state.originalOptionGeoLevels,
      optionYears: _state.originalOptionYears
    });
  }
  // switch (_specificMeasure) {
  //   case "Location quotient - A measure of the concentration of technology workers within each region":
  //     return Object.assign({}, _state, {
  //       selectedSpecificMeasure: _specificMeasure,
  //       optionSectors: _state.originalOptionSectors,
  //       selectedSector: []
  //     });
  //     case "Location quotient - A measure of the concentration of technology workers within each region":
  //       return Object.assign({}, _state, {
  //         selectedSpecificMeasure: _specificMeasure,
  //         optionSectors: _state.originalOptionSectors,
  //         selectedSector: []
  //       });
  //       case "Location quotient - A measure of the concentration of technology workers within each region":
  //         return Object.assign({}, _state, {
  //           selectedSpecificMeasure: _specificMeasure,
  //           optionSectors: _state.originalOptionSectors,
  //           selectedSector: []
  //         });
  //   default:
  //     return Object.assign({}, _state, {
  //       selectedSpecificMeasure: _specificMeasure,
  //       optionSectors:_state.originalOptionSectors,
  //       selectedSector: []
  //     });
  // };
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PROGRESS_BAR:
      return Object.assign({}, state, {
        loading: action.loading
      });
    case SET_STUDENT_MAP_DATA:
      return Object.assign({}, state, {
        // the data to display (with filter applied)
        loading: action.loading,
        data: action.data,
        tableData: action.tableData,
        statsState: action.statsState,
        tableDisplayFilters: {
          selectedTypeMeasure: action.tableDisplayFilters.selectedTypeMeasure,
          selectedSpecificMeasure: action.tableDisplayFilters.selectedSpecificMeasure, 
          selectedSector: typeof action.tableDisplayFilters.selectedSector === "undefined"? 
          state.tableDisplayFilters.selectedSector: action.tableDisplayFilters.selectedSector
        },
        tableDisplayData: {displayData: [], selectedArea: "", selectedState: ""},
        statsNation: action.statsNation,
        // the default color set in the server side
        colors: action.colors,
        // the legend keys
        legend_keys: action.legend_keys,
        
        optionTypeMeasures: action.optionTypeMeasures,
        typeMeasuresVSSpecificMeasures: action.typeMeasuresVSSpecificMeasures,
        // geoLevel: state.geoLevel,

        optionGeoLevels: action.optionGeoLevels,
        selectedGeoLevel: action.selectedGeoLevel,
        originalOptionGeoLevels: action.optionGeoLevels,

        optionYears: action.optionYears,
        selectedYear: action.selectedYear,
        originalOptionYears: action.optionYears,

        // the available selection of sectors
        optionSectors: action.optionSectors,
        selectedSector: action.selectedSector,
        originalOptionSectors: action.optionSectors,

        selectedTypeMeasure: action.selectedTypeMeasure,
        optionSpecificMeasures: updateSpecificMeasures(
          action.selectedTypeMeasure,
          action.typeMeasuresVSSpecificMeasures
        ),
        selectedSpecificMeasure: action.selectedSpecificMeasure
      });
    case FILTER_STUDENT_MAP_DATA:
      return Object.assign({}, state, {
        loading: action.loading,
        data: action.data,
        colors: action.colors,
        tableData: action.tableData,
        tableDisplayFilters: {
          selectedTypeMeasure: action.tableDisplayFilters.selectedTypeMeasure,
          selectedSpecificMeasure: action.tableDisplayFilters.selectedSpecificMeasure, 
          selectedSector: typeof action.tableDisplayFilters.selectedSector === "undefined"? 
          state.tableDisplayFilters.selectedSector: action.tableDisplayFilters.selectedSector
        },
        tableDisplayData: {displayData: [], selectedArea: "", selectedState: ""},
        statsState: action.statsState,
        statsNation: action.statsNation,
        legend_keys: action.legend_keys,
        geoLevel: action.geoLevel,
        year: action.year,
        selectedGeoLevel: action.selectedGeoLevel,
        selectedYear: action.selectedYear
      });
    case SET_TABLE_DATA:
      return Object.assign({}, state, {
        tableDisplayData: filterTableByArea(
          action.selectedArea,
          action.selectedState,
          state.tableData,
          state.statsState,
          state.statsNation
        ),
      });
    case UPDATE_OPTION_SPECIFIC_MEASURES:
      return Object.assign({}, state, {
        selectedTypeMeasure: action.selectedTypeMeasure,
        optionSpecificMeasures: updateSpecificMeasures(
          action.selectedTypeMeasure,
          state.typeMeasuresVSSpecificMeasures
        )
      });
    case UPDATE_GEO_LEVEL:
      return Object.assign({}, state, {
        selectedGeoLevel: action.selectedGeoLevel
      });
    case UPDATE_YEAR:
      return Object.assign({}, state, {
        selectedYear: action.selectedYear
      });
      
    case UPDATE_TYPE_MEASURE:
      let options = updateSpecificMeasures(
        action.selectedTypeMeasure,
        state.typeMeasuresVSSpecificMeasures
      );
      let result = {};
      result.selectedTypeMeasure = action.selectedTypeMeasure;
      result.optionSpecificMeasures = options;
      result.selectedSpecificMeasure = options[0];
      let _result = updateFilters(result.selectedSpecificMeasure, state);
      result = Object.assign({}, state, _result, result);
      return result;
    case UPDATE_SPECIFIC_MEASURE:
      return updateFilters(action.selectedSpecificMeasure, state);
    case UPDATE_SECTOR:
      return Object.assign({}, state, {
        selectedSector: action.selectedSector
      });
    default:
      return state;
  }
}
