import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import ConnectedOptionsfield from "./Optionsfield";
import ConnectedDataTable from "./DataTable";
import "./Map.css";
import {
  requestJobs,
  filterTable,
  geoNameMapping,
} from "../../actions/studentMapActions";
import { connect } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";

mapboxgl.accessToken =
  "pk.eyJ1IjoiaGFvaHVpY2hlbjE5ODEiLCJhIjoiY2tuemJpeDgwMDNqbTJ2cnRzd3pjNW1lYSJ9.Tko-h6TDtJua7CYE5iPWSw";


const filterTableByArea = (area, selectedState, tableDataOriginal, statsState, statsNation) => {
    let displayData = {};
    let state_feature = "";
    // console.log(area);
    // console.log(selectedState);
    for (var i=0; i<statsState.length; i++) {
      let feature = statsState[i];
      if (feature.region_name === selectedState) {
        state_feature = feature;
      }
    }; 
  
    for (var k=0; k<tableDataOriginal.length; k++) {
        let feature = tableDataOriginal[k];
        if (feature.region_name === area) {
          displayData["Region:"]= feature.counts === "no_data" ? "no data" : feature.counts;
          displayData[selectedState+":"]  = state_feature.counts;
          displayData["Nation:"] = statsNation.counts;
          break; 
        }
    };
    return displayData;
  }

const Map = (props) => {
  const mapContainerRef = useRef(null);
  const mapLegendRef = useRef(null);
  const [map, setMap] = useState(null);

  const { requestJobs, filterTable } = props;
  const [open, setOpen] = React.useState(true);

  // const [loadMoreOpen, setLoadMoreOpen] = React.useState(true);

  const expand_clusters = (e) => {
    const features = map.queryRenderedFeatures(e.point, {
      layers: ["tech_hotspot_clusters"],
    });
    const clusterId = features[0].properties.cluster_id;
    map
      .getSource("tech_hotspot_stats")
      .getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) return;

        map.easeTo({
          center: features[0].geometry.coordinates,
          zoom: zoom,
        });
      });
  };

  const change_cursor_to_pointer =  (e) => {
    // Change the cursor style as a UI indicator.
    map.getCanvas().style.cursor = "pointer";
  };

  const change_cursor_to_default =  (e) => {
    map.getCanvas().style.cursor = "default";
  };

  const remove_layers = (_map) => {
    if (_map.getLayer("sa_color")) {
      // _map.off("click", "sa_color", filterTable);
      _map.removeLayer("sa_color");
    }
    if (_map.getSource("sa_stats")) {
      _map.removeSource("sa_stats");
    }

    const hotspot_layers = [
      "tech_hotspot_clusters",
      "hotspot_cluster_count",
      "hotspot_unclustered_point",
      "hotspot_unclustered_count",
    ];
    for (let i = 0; i < hotspot_layers.length; i++) {
      if (_map.getLayer(hotspot_layers[i])) {
        _map.removeLayer(hotspot_layers[i]);
      }
    }
    if (_map.getSource("tech_hotspot_stats")) {
      _map.removeSource("tech_hotspot_stats");
    }

    if (document.getElementById("MAP_LEGEND")) {
      mapLegendRef.current.removeChild(document.getElementById("MAP_LEGEND"));
    }
  };

  useEffect(() => {
    const lng = 135;
    const lat = -26;
    const zoom = 4;
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/light-v10",
      center: [lng, lat],
      zoom: zoom,
      cursor: "default",
    });
    map.addControl(new mapboxgl.NavigationControl());
    // Set the cursor to the default style
    map.getCanvas().style.cursor = "default";

    map.on("load", () => {
      // var mapCanvas = document.getElementsByClassName('mapboxgl-map')[0];
      // // var mapCanvasParent = mapCanvas.parent();
      // // var mapCanvasParent = mapContainerRef.current.parentElement.offsetHeight;
      // mapCanvas.style.height = mapContainerRef.current.parentElement.parentElement.offsetHeight + 'px';
      // map.resize();
      requestJobs();
      setMap(map);
    });

    // Clean up on unmount
    return () => map.remove();
  }, []); 

  const paint = () => {
    if (map) {
      const {
        data,
        selectedGeoLevel,
        categoryColors,
        legend_keys,
        selectedSpecificMeasure,
        tableDataOriginal, statsState, statsNation
      } = props;

      remove_layers(map);

      const close_popup =  (e) => {
        // Change the cursor style as a UI indicator.
        map.getCanvas().style.cursor = "default";
        popup.remove();
      };

      const build_popup_table = (e) => {
        // Change the cursor style as a UI indicator.
        map.getCanvas().style.cursor = "pointer";

        const properties = e.features[0].properties;

        let selected_area = properties[geoNameMapping[e.features[0].layer.metadata.geoLevel] + "_NAME21"];

        const selectedState = properties["STE_NAME21"];

        const displayData = filterTableByArea(selected_area, selectedState, tableDataOriginal, statsState, statsNation);

        // Create the table and its components
        const table = document.createElement('table');

        // Create the table caption
        const caption = document.createElement('caption');
        caption.textContent = selected_area;
        table.appendChild(caption);

        const thead = document.createElement('thead');
        const tbody = document.createElement('tbody');
        const headerRow = document.createElement('tr');
        const thKey = document.createElement('th');
        const thValue = document.createElement('th');

        thKey.textContent = 'Metrics';
        thValue.textContent = 'Value';
        headerRow.appendChild(thKey);
        headerRow.appendChild(thValue);
        thead.appendChild(headerRow);
        table.appendChild(thead);
        table.appendChild(tbody);

        // Populate the table with the dictionary data
        for (const key in displayData) {
          const value = displayData[key];
          const row = document.createElement('tr');
          const tdKey = document.createElement('td');
          const tdValue = document.createElement('td');
  
          tdKey.textContent = key;
          tdValue.textContent = value;
          row.appendChild(tdKey);
          row.appendChild(tdValue);
          tbody.appendChild(row);
        }
        
        popup.setLngLat(e.lngLat).setHTML(table.outerHTML).addTo(map);
      };


      map.addSource("sa_stats", {
        type: "geojson",
        // Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
        // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
        data: data,
      });

      map.addLayer({
        id: "sa_color",
        type: "fill",
        source: "sa_stats",
        layout: {},
        metadata: { geoLevel: selectedGeoLevel },
        paint: {
          "fill-color": ["get", "color"],
          "fill-opacity": 0.9,
          "fill-outline-color": "#808080",
        },
      });

      // console.log(map.getLayer("sa_color"));
      // map.on("click", "sa_color", filterTable);

      // Create a popup, but don't add it to the map yet.
      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      });

      // map.on("mousemove", "sa_color", (e) => {
      //   // Change the cursor style as a UI indicator.
      //   map.getCanvas().style.cursor = "pointer";

      //   // Copy coordinates array.
      //   let description =
      //     e.features[0].properties[
      //       geoNameMapping[e.features[0].layer.metadata.geoLevel] + "_NAME21"
      //     ];
      //   popup.setLngLat(e.lngLat).setHTML(description).addTo(map);
      // });

      map.on("mousemove", "sa_color", build_popup_table);

      map.on("mouseleave", "sa_color", () => {
        map.getCanvas().style.cursor = "default";
        popup.remove();
      });

      setMap(map);

      if (document.getElementById("MAP_LEGEND")) {
        mapLegendRef.current.removeChild(document.getElementById("MAP_LEGEND"));
      }
      var legendItems = document.createElement("div");
      legendItems.setAttribute("id", "MAP_LEGEND");

      var legendTitle = document.createElement("div");
      // title.style.backgroundColor = categoryColors[i];

      var span = document.createElement("span");
      span.innerHTML = selectedSpecificMeasure;
      legendTitle.appendChild(span);
      legendItems.appendChild(legendTitle);

      for (let i = 0; i < legend_keys.length; i++) {
        // create legend
        var item = document.createElement("div");
        var key = document.createElement("span");
        key.className = "legend-key";
        key.style.backgroundColor = categoryColors[i];

        var value = document.createElement("span");
        value.innerHTML = legend_keys[i];
        item.appendChild(key);
        item.appendChild(value);
        legendItems.appendChild(item);
      }

      mapLegendRef.current.appendChild(legendItems);
    }
  };

  const paint_hotspots = () => {
    if (map) {
      const { data } = props;
      const cluster_color = data.features[0].properties.color;
      
      // Create a popup, but don't add it to the map yet.
      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      });

      const close_popup =  (e) => {
        // Change the cursor style as a UI indicator.
        map.getCanvas().style.cursor = "default";
        popup.remove();
      };


      const build_popup_table = (e) => {
        // Change the cursor style as a UI indicator.
        map.getCanvas().style.cursor = "pointer";

        const properties = e.features[0].properties;
        // Copy coordinates array.
        let hotspot_name = properties["SA2_NAME21"];

        const searchStrings = ['Location Quotient', 'Digital workers'];
        const matchingKeys = [];

        searchStrings.forEach(function(searchString) {
          for (const key in properties) {
            if (key.startsWith(searchString)) {
              matchingKeys.push(key);
            }
          }
        });
        // matchingKeys.forEach(function(matchingKey){
        //   description = description.concat('<br>', matchingKey + ": " + e.features[0].properties[matchingKey]);
        // });

        // Create the table and its components
        const table = document.createElement('table');

        // Create the table caption
        const caption = document.createElement('caption');
        caption.textContent = hotspot_name;
        table.appendChild(caption);

        const thead = document.createElement('thead');
        const tbody = document.createElement('tbody');
        const headerRow = document.createElement('tr');
        const thKey = document.createElement('th');
        const thValue = document.createElement('th');

        thKey.textContent = 'Metrics';
        thValue.textContent = 'Value';
        headerRow.appendChild(thKey);
        headerRow.appendChild(thValue);
        thead.appendChild(headerRow);
        table.appendChild(thead);
        table.appendChild(tbody);

        // Populate the table with the dictionary data
        for (const key in properties) {
          if (matchingKeys.includes(key)){
            // console.log(key);
            const value = properties[key];
            const row = document.createElement('tr');
            const tdKey = document.createElement('td');
            const tdValue = document.createElement('td');
  
            tdKey.textContent = key;
            tdValue.textContent = value;
            row.appendChild(tdKey);
            row.appendChild(tdValue);
            tbody.appendChild(row);
          }
        }
        
        popup.setLngLat(e.lngLat).setHTML(table.outerHTML).addTo(map);
      };
      
      map.on("off", "tech_hotspot_clusters", expand_clusters);
      map.on("off", "tech_hotspot_clusters", change_cursor_to_pointer);
      map.on("off", "hotspot_unclustered_point", close_popup);
      map.on("off", "hotspot_unclustered_point", change_cursor_to_default);
      map.on("off", "hotspot_unclustered_point", build_popup_table);

      remove_layers(map);

      map.addSource("tech_hotspot_stats", {
        type: "geojson",
        // Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
        // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
        data: data,
        cluster: true,
        clusterProperties: {
          DW_sum: ["+", ["to-number", ["get", "Digital workers"]]],
        },
        clusterMaxZoom: 12, // Max zoom to cluster points on
        clusterRadius: 30, // Radius of each cluster when clustering points (defaults to 50)
      });


      map.addLayer({
        id: "tech_hotspot_clusters",
        type: "circle",
        source: "tech_hotspot_stats",
        filter: ["has", "point_count"],
        // filter: ["!=", "cluster", true],
        // metadata: { geoLevel: selectedGeoLevel },
        paint: {
          "circle-color": cluster_color,
          // ["get", "color"],
          "circle-stroke-width": 3,
          "circle-stroke-color": "#6a6a6a",
          "circle-radius": [
            "interpolate",
            ["linear"],
            ["get", "DW_sum"],
            0,
            10,
            1000,
            20,
            10000,
            30,
            100000,
            40,
          ],
        },
      });

      map.addLayer({
        id: "hotspot_cluster_count",
        type: "symbol",
        source: "tech_hotspot_stats",
        filter: ["has", "point_count"],
        // filter: ["!=", "cluster", true],
        layout: {
          // "text-field": ["get", "point_count_abbreviated"],
          "text-field": ["get", "DW_sum"],
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 12,
        },
      });

      map.addLayer({
        id: "hotspot_unclustered_point",
        type: "circle",
        source: "tech_hotspot_stats",
        filter: ["!", ["has", "point_count"]],
        paint: {
          "circle-color": ["get", "color"],
          "circle-radius": ["get", "radius"],
        },
      });

      map.addLayer({
        id: "hotspot_unclustered_count",
        type: "symbol",
        source: "tech_hotspot_stats",
        filter: ["!", ["has", "point_count"]],
        // filter: ["!=", "cluster", true],
        layout: {
          // "text-field": ["get", "point_count_abbreviated"],
          "text-field": ["to-string", ["to-number", ["get", "Digital workers"]]],
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 12,
        },
      });

      map.on("mousemove", "hotspot_unclustered_point", build_popup_table);

      map.on("mouseleave", "hotspot_unclustered_point", close_popup);

      map.on("click", "tech_hotspot_clusters", expand_clusters);

      map.on("mousemove", "tech_hotspot_clusters", change_cursor_to_pointer);

      map.on("mouseleave", "hotspot_unclustered_point", change_cursor_to_default);

      setMap(map);
    }
  };

  useEffect(() => {
    if (!props.data) return;
    if (props.selectedSpecificMeasure.includes("Hotspots")) {
      paint_hotspots();
    } else {
      paint();
    }
  }, [props.data]);

  return (
    <div className="row" style={{ height: "75vh", width: "100%" }}>
      <div className="col s3" style={{ paddingLeft: "30.250px" }}>
        <ConnectedOptionsfield />
      </div>
      <div className="col s9" style={{ position: "relative" }}>
        <div className="map-overlay">
          <div className="map-overlay-inner" ref={mapLegendRef}></div>
        </div>
        <div
          ref={mapContainerRef}
          style={{ height: "75vh", width: "100%" }}
        ></div>
      </div>
      {/* <div className="col s4" style={{ paddingTop: "10.250px" }}>
        <Collapse in={open}>
          <Alert
            severity="info"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            click on a region of the map to generate a data table
          </Alert>
        </Collapse>
        <ConnectedDataTable />

        <div className="row">
          <p
            style={{ fontSize: "80%", lineHeight: 1 }}
            className="grey-text text-darken-1"
          >
            Please be aware that differences in statistics are likely to reflect
            random factors when they are based on a small number of
            observations. For this reason, no colour-coding is used on the map
            where the difference between number of people or number of job ads
            is less than 5. In addition, where there are small numbers of people
            for a given region, the statistics have been modified slightly to
            preserve individuals' confidentiality. These modifications can lead
            to small discrepancies between regional, state and national
            statistics.
          </p>
        </div>
      </div> */}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    data: state.student.data,
    selectedGeoLevel: state.student.selectedGeoLevel,
    categoryColors: state.student.colors,
    legend_keys: state.student.legend_keys,
    selectedSpecificMeasure: state.student.selectedSpecificMeasure,
    tableData: state.tableData,
    tableDataOriginal: state.student.tableData,
    statsState: state.student.statsState,
    statsNation: state.student.statsNation
  };
}

const ConnectedMap = connect(mapStateToProps, { requestJobs, filterTable })(
  Map
);
export default ConnectedMap;
