import React, { Component } from "react";
import { Link } from "react-router-dom";
// import adzunaLogo from "../../logo/Adzuna-logo-80x50.png";
import CSIROLogo from "../../logo/CSIRO_Logo.svg.png";
class Navbar extends Component {
  render() {
    return (
      <div className="navbar">
        <nav className="z-depth-0">
          <div className="nav-wrapper">
            <a href="https://www.csiro.au/">
              <img
                src={CSIROLogo}
                width="50"
                height="50"
                style={{ marginTop: "5px", marginLeft: "10px" }}
                alt="CSIRO Logo"
              ></img>
            </a>
            <Link
              to="/"
              style={{
                fontFamily: "monospace",
                marginLeft: "10px" 
              }}
              className="brand-logo left white-text"
            >
              {" "}
              CSIRO Tech Industry Map
            </Link>
            {/* <a href="https://www.adzuna.com.au/">
              <i className="right">
                <img
                  src={adzunaLogo}
                  width="80"
                  height="50"
                  style={{ marginTop: "5px" }}
                  alt="Adzuna Logo"
                ></img>
              </i>
            </a> */}
          </div>
        </nav>
      </div>
    );
  }
}
export default Navbar;
