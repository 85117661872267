import React from "react";
import { filterMap, updateGeoLevel,  updateYear,
  updateTypeMeasure, updateSpecificMeasure,
  updateSector} from "../../actions/studentMapActions";
import { connect } from "react-redux";

import { withStyles } from '@material-ui/core/styles';

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import SpecificMeasureInfo from "./Modal/SpecificMeasureInfoModal";
import RegionInfo from "./Modal/RegionInfoModal";
// import SectorInfo from "./Modal/SectorInfoModal";

import { LinearProgress } from '@material-ui/core';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 5,
    width: "80%",
    borderRadius: 4,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 4,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);


const Optionsfield = (props) => {

  const {
    loading,
    handleChange,
    optionSpecificMeasures,
    selectedSpecificMeasure, setSelectedSpecificMeasure,
    optionGeoLevels,
    selectedGeoLevel, setSelectedGeoLevel,
    optionYears,
    selectedYear, setSelectedYear
    } = props;

  const onSubmit = (e) => {
    e.preventDefault();
    handleChange(selectedSpecificMeasure, selectedGeoLevel, selectedYear);
  };

  return (
    <form onSubmit={onSubmit}>
      <div  style={{maxHeight: 500, overflow: 'auto'}}>  
        {/* <div className="row" style={{ marginBottom: "0px" }}>
          <Autocomplete
            className="col s10"
            id="control-type-measure"
            options={optionTypeMeasures}
            value={selectedTypeMeasure}
            onChange={(event, newValue) => {
              // console.log(newValue);
              setSelectedTypeMeasure(newValue);
            }}
            noOptionsText={'This filter is not available for your specific measure'}
            renderInput={(params) => (
              <TextField {...params} label="Step 1: Select the type of information you want" margin="normal" />
            )}
          /><TypeMeasureInfo className="col s2"/>
        </div> */}
        <div className="row" style={{ marginBottom: "0px" }}>
          <Autocomplete 
            className="col s10"
            id="control-specific-measures"
            options={optionSpecificMeasures}
            value={selectedSpecificMeasure}
            onChange={(event, newValue) => {
              // console.log(newValue);
              setSelectedSpecificMeasure(newValue);
            }}
            noOptionsText={'This filter is not available for your specific measure'}
            renderInput={(params) => (
              <TextField {...params} label="Select metric" margin="normal" />
            )}
          /><SpecificMeasureInfo className="col s2"/>
        </div>
        <div className="row" style={{ marginBottom: "0px" }}>
          <Autocomplete
            className="col s10"
            id="control-geo-levels"
            options={optionGeoLevels}
            value={selectedGeoLevel}
            // value={'SA3'}
            onChange={(event, newValue) => {
              // console.log(newValue);
              setSelectedGeoLevel(newValue);
            }}
            noOptionsText={'This filter is not available for your specific measure'}
            renderInput={(params) => (
              <TextField {...params} label="Geographic Regions" margin="normal" />
            )}
          /><RegionInfo className="col s2"/>
        </div>
        <div className="row" style={{ marginBottom: "0px" }}>
          <Autocomplete
            className="col s10"
            id="control-years"
            options={optionYears}
            value={selectedYear}
            // value={'SA3'}
            onChange={(event, newValue) => {
              // console.log(newValue);
              setSelectedYear(newValue);
            }}
            noOptionsText={'This filter is not available for your specific measure'}
            renderInput={(params) => (
              <TextField {...params} label="Year" margin="normal" />
            )}
          />
        </div>
        {/* <div className="row" style={{ marginBottom: "0px" }}>
          <Autocomplete
            className="col s10"
            multiple
            id="control-AQFs"
            options={optionAQFs}
            value={selectedAQFs}
            disableCloseOnSelect
            onChange={(event, newValue) => {
              // console.log(newValue);
              setSelectedAQFs(newValue);
            }}
            noOptionsText={'This filter is not available for your specific measure'}
            getOptionLabel={(option) => option}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />{option}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Qualification Levels" margin="normal" />
            )}
          /><QualInfo className="col s2"/>
        </div> */}
        {/* <div className="row" style={{ marginBottom: "0px" }}>
          <Autocomplete
            className="col s10"
            id="control-sectors"
            options={optionSectors}
            value={selectedSector}
            onChange={(event, newValue) => {
              // console.log(newValue);
              setSelectedSector(newValue);
            }}
            noOptionsText={'This filter is not available for your specific measure'}
            renderInput={(params) => (
              <TextField {...params} label="Sector of Employment" margin="normal" />
            )}
          /><SectorInfo className="col s2"/>
        </div> */}
        {/* <div className="row" style={{ marginBottom: "0px" }}>
          <Autocomplete
            className="col s10"
            id="control-FoEs"
            options={optionFoEs}
            value={selectedFoE}
            onChange={(event, newValue) => {
              // console.log(newValue);
              setSelectedFoE(newValue);
              setSelectedNarrowFoEs([]);
            }}
            noOptionsText={'This filter is not available for your specific measure'}
            renderInput={(params) => (
              <TextField {...params} label="Broad Field of Education" margin="normal" />
            )}
          /><FoEInfo className="col s2"/>
        </div> */}
        {/* <div className="row" style={{ marginBottom: "0px" }}>
          <Autocomplete
            className="col s10"
            multiple
            id="control-Narrow=FoEs"
            disableCloseOnSelect
            options={optionNarrowFoEs}
            value={selectedNarrowFoEs}
            onChange={(event, newValue) => {
              // console.log(newValue);
              setSelectedNarrowFoEs(newValue);
            }}
            noOptionsText={'Options available when Broad Field of Education is selected'}
            getOptionLabel={(option) => option}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />{option}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Narrow Field of Education" margin="normal" />
            )}
          /><FoEInfo className="col s2"/>
        </div> */}
      </div>
      <div>
       {loading && <BorderLinearProgress /> }
      </div>
      <div className="row" style={{ marginLeft:"10px", marginRight:"80px", marginTop:"20px", marginBottom: "0px" }}>
            <p style ={{ fontSize:"80%", lineHeight:1 }} className="grey-text text-darken-1">
            {/* This map allows users to explore the types of jobs that have been advertised in each region and where Indigenous talent is sought by employers. It also shows what formal qualifications and industry experience workers and graduates have across Australia. */}
            </p>
          </div>
      <div className="row" style={{ marginLeft:"10px", marginBottom: "0px" }}>
        <button
          style={{
            width: "250px",
            borderRadius: "3px",
            letterSpacing: "1.5px",
            marginTop: "1rem",
            background: "#009dc4",
          }}
          type="submit"
          className="btn"
        >
          Redraw
        </button>
      </div>
    </form>
  );
};

function mapStateToPropsOptionsfield(state) {
  return {
    // different measure
    loading: state.student.loading,
    optionTypeMeasures: state.student.optionTypeMeasures,
    selectedTypeMeasure:state.student.selectedTypeMeasure,
    optionSpecificMeasures: state.student.optionSpecificMeasures,
    selectedSpecificMeasure:state.student.selectedSpecificMeasure,
    optionGeoLevels: state.student.optionGeoLevels,
    selectedGeoLevel: state.student.selectedGeoLevel,
    optionYears: state.student.optionYears,
    selectedYear: state.student.selectedYear,
    optionSectors: state.student.optionSectors,
    selectedSector: state.student.selectedSector
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleChange: (specificMeasure, geoLevel, year) => {
      dispatch(filterMap(specificMeasure, geoLevel, year));
    },
    setSelectedTypeMeasure: (value) => {
      dispatch(updateTypeMeasure(value));
    },
    setSelectedSpecificMeasure: (value) => {
      dispatch(updateSpecificMeasure(value));
    },
    setSelectedGeoLevel: (value) => {
      dispatch(updateGeoLevel(value));
    },    
    setSelectedYear: (value) => {
      dispatch(updateYear(value));
    },    
    setSelectedSector: (value) => {
      dispatch(updateSector(value));
    }
  };
};

const ConnectedOptionsfield = connect(
  mapStateToPropsOptionsfield,
  mapDispatchToProps
)(Optionsfield);
export default ConnectedOptionsfield;
