import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import InfoIcon from "@material-ui/icons/InfoOutlined";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 800,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function SimpleModal() {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const body = (
            <div style={modalStyle} className={classes.paper}>
              <h4 id="simple-modal-title">Metrics</h4>
              <p id="simple-modal-description"></p>
              <p>
                The metrics you can select in this category include:{" "}
              </p>
              <ul id="disclaimer-ul" className="left-align">
                <li>
                  <b>Location quotient</b>: 
                  A measure of the concentration of technology workers within each region. The definition of the measure Location Quotient can be found in this <a href="./img/What_is_a_Location_Quotient.pdf" target="_blank" rel="noopener noreferrer">file</a>. 
                </li>
                <li>
                  <b>Technology workers</b>: Counts of workers in digital technology occupations within the region, captured from the
                  Australian Bureau of Statistics 2021 Census data.
                </li>
                <li>
                  <b>Capital City Hotspots</b>: Idenitifed as digital industry hotspots.
                  The definition of the hotspots can be found in this <a href="./img/Dummy_Report.pdf" target="_blank" rel="noopener noreferrer">file</a>.
                </li>
                <li>
                  <b>Regional Niche Hotspots</b>: With niche specialisations in digital technology. The definition of the hotspots can be found in this <a href="./img/Dummy_Report.pdf" target="_blank" rel="noopener noreferrer">file</a>.
                </li>
              </ul>
            </div>
          );
          // "Location quotient - A measure of the concentration of technology workers within each region",
          // "Technology workers - The count of workers in digital technology occupations within the region",
          // "Capital City Hotspots - Idenitifed as digital industry hotspots",
          // "Regional Niche Hotspots - With niche specialisations in digital technology", 
      
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <InfoIcon style={{ marginTop: "4rem" }} onClick={handleOpen}></InfoIcon>
      <Modal
        id="Specific-Measure-Info"
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};

