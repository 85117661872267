import React, { Component } from "react";
import { Link } from "react-router-dom";
// import adzunaLogo from "../../logo/Adzuna-logo-80x75.png";
import d61Logo from "../../logo/CSIRO_Logo.svg.png";
class Bottombar extends Component {
  render() {
    return (
       <footer className="page-footer lighten-5" style={{ position:"fixed", paddingTop: "0px", bottom:"0px", left:"0px", width:"100%" }}>
          <div className="row container valign-wrapper lighten-5al">
              <div className="col m12 s4 l4">
                <a
                  href="https://www.csiro.au/"
                  style={{
                    fontFamily: "monospace"
                  }}
                  className="col brand-logo left black-text"
                  >
                  <img src={d61Logo} width="75" height="75" alt="Data61 Logo"></img>
                </a>
                {/* <a
                  href="https://www.adzuna.com.au/"
                  style={{
                    fontFamily: "monospace"
                  }}
                  className="col brand-logo left black-text"
                  >
                  <img src={adzunaLogo} width="80" height="75" margin-bottom="12" alt="Adzuna Logo"></img> 
                </a> */}
              </div>
              <div className="col m12 s4 l4">
                <ul>
                  <li><Link className="white-text text-darken-2" to="/">Home</Link></li>
                  {/* <li><Link className="white-text text-darken-2" to="/background">Background</Link></li> */}
                  <li><Link className="white-text text-darken-2" to="/faq">FAQ</Link></li>
                  <li><Link className="white-text text-darken-2" to="/cite">Citation</Link></li>
                  {/* <li><Link className="white-text text-darken-2" to="/disclaimerStud">Disclaimer for Student Portal</Link></li>
                  <li><Link className="white-text text-darken-2" to="/disclaimerEmp">Disclaimer for Employer Portal</Link></li> */}
                </ul>
              </div>
              <div className="col m12 s4 l4">
                <ul>
                  <li><Link className="white-text text-darken-2" to="/feedback">Feedback</Link></li>
                  <li><a className="white-text text-darken-2" href="https://www.csiro.au/en/about/Corporate-governance/Access-to-information">Access to information</a></li>
                </ul>
              </div>
          </div>
          <div className="footer-copyright black valign-wrapper row" style={{
            padding: "0px", marginBottom: "0px"}}>
            <div className="container row">
              <ul className="list-inline ">
                  <li className ="col">
                              <a className="white-text" href="https://www.csiro.au/en/about/Policies/Legal/Copyright">Copyright</a>
                  </li>
                  <li className ="col">
                              <a className="white-text" href="https://www.csiro.au/en/about/Policies/Legal/Legal-notice">Legal notice and disclaimer</a>
                  </li>
                  <li className ="col">
                              <a className="white-text" href="https://www.csiro.au/en/about/Policies/Privacy">Your privacy</a>
                  </li>
                  <li className ="col">
                              <a className="white-text" href="https://www.csiro.au/en/about/Policies">Policy & Guidelines</a>
                  </li>
                  <li className ="col">
                              <a className="white-text" href="https://www.csiro.au/en/about/Policies/Legal/Accessibility">Accessibility</a>
                  </li>
                  <li className ="col">
                              <a className="white-text" href="https://www.csiro.au/en/contact">Contact us</a>
                  </li>
              </ul>
            </div>
          </div>
      </footer>
    );
  }
}
export default Bottombar;
