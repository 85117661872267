export const GET_ERRORS = "GET_ERRORS";

export const SET_STUDENT_MAP_DATA="SET_STUDENT_MAP_DATA";
export const FILTER_STUDENT_MAP_DATA="FILTER_STUDENT_MAP_DATA";
export const SET_TABLE_DATA="SET_TABLE_DATA";
export const UPDATE_OPTION_SPECIFIC_MEASURES="UPDATE_OPTION_SPECIFIC_MEASURES";

export const UPDATE_GEO_LEVEL="UPDATE_GEO_LEVEL";

export const UPDATE_YEAR="UPDATE_YEAR";

export const UPDATE_TYPE_MEASURE="UPDATE_TYPE_MEASURE";
export const UPDATE_SPECIFIC_MEASURE="UPDATE_SPECIFIC_MEASURE";

export const UPDATE_SECTOR="UPDATE_SECTOR";

export const SET_PROGRESS_BAR="SET_PROGRESS_BAR";
