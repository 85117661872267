import React, { Component } from "react";
import { Link } from "react-router-dom";

class EmployerDisclaimer extends Component {
  render() {
    return (
      <div style={{ height: "75vh" }} className="container valign-wrapper">
        <div className="row">
          <div className="col s12 center-align" style={{ height: "60vh", overflow: 'auto'}}>
            <h4>
              <b>For your information:</b>
            </h4>
            <p className="left-align">Please be aware that the wording of job advertisements and qualification requirements represents only
                        a small component of the effort needed to successfully attract and retain Aboriginal and/or
                        Torres Strait Islander workers. Employers who are committed to supporting improved employment
                        outcomes for Aboriginal and/or Torres Strait Islander peoples should ensure that they provide culturally safe
                        environments. They can assist more broadly by getting involved in school programs
                        and partnerships with Indigenous communities.
            </p>
            <p className="left-align">The material and information contained on this website and dashboard is for general information
                        only. You should not rely solely upon this material when making decisions. Some considerations include: 
            </p>
            <ul id="disclaimer-ul" className="left-align">
                <li>Our figures on the number of Aboriginal and/or Torres Strait Islander people studying or
                                working in a particular field only capture those people who chose to identify their
                                Indigeneity in the reports that the Indigenous Jobs Map draws upon.</li>
                <li>Machine learning was used to identify Indigenous-focused job ads. We can expect some errors
                                in the classifications that the machine provided. Even human experts sometimes find it
                                difficult to make these judgements.</li>
                <li>The Indigenous Jobs Map counts the number of people studying and working in a given region at the time when
                                the data was collected. The actual pool of Aboriginal and/or Torres Strait Islander workers may
                                be smaller or larger due to people migrating to different regions or being willing to
                                commute to a workplace outside of the region in which they live.</li>
            </ul>
            <br />

          </div>
          {/* <div className="col s12 center-align">
            <p
              style={{ fontSize: "15px" }}
              className="flow-text grey-text text-darken-1"
            >
            </p>
          </div> */}
          <div className="col s12 center-align">
              <Link
                to="/employer_map"
                style={{
                  width: "400px",
                  borderRadius: "3px",
                  letterSpacing: "1.5px",
                }}
                className="btn btn-large waves-effect waves-light hoverable blue accent-3"
              >
                Launch the employer map
              </Link>
          </div>
        </div>
      </div>
    );
  }
}
export default EmployerDisclaimer;
