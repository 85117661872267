import axios from "axios";
import { SET_STUDENT_MAP_DATA, FILTER_STUDENT_MAP_DATA, 
  SET_TABLE_DATA, UPDATE_OPTION_SPECIFIC_MEASURES,
  UPDATE_GEO_LEVEL, UPDATE_YEAR, UPDATE_TYPE_MEASURE, UPDATE_SPECIFIC_MEASURE,
  UPDATE_SECTOR, SET_PROGRESS_BAR} from "./types";

// Set map data
export const setProgressBar = (is_loading) => {
  return {
    type: SET_PROGRESS_BAR,
    loading: is_loading
  };
};

export const geoNameMapping = {"Local Government Areas": "LGA",
"Statistical Area Level 2" : "SA2",
"Statistical Area Level 3" : "SA3",
"Statistical Area Level 4" : "SA4"};

export const setMapData = data => {
  return {
    type: SET_STUDENT_MAP_DATA,
    loading: false,
    colors: data.colors,
    legend_keys: data.legend_keys,
    data: data.data,
    tableData: data.tableData,
    tableDisplayFilters: {
      selectedTypeMeasure: data.selectedTypeMeasure,
      selectedSpecificMeasure: data.selectedSpecificMeasure, 
      selectedSector: data.selectedSector},
    statsState: data.statsState,
    statsNation: data.statsNation,
    optionTypeMeasures: data.typeMeasures,
    selectedTypeMeasure: data.selectedTypeMeasure,
    typeMeasuresVSSpecificMeasures: data.typeMeasuresVSSpecificMeasures,
    // update the measure filter
    selectedSpecificMeasure: data.selectedSpecificMeasure,
    // update the geo filter
    optionGeoLevels : data.geoLevels,
    selectedGeoLevel: data.selectedGeoLevel,

    // update the year filter
    optionYears : data.years,
    selectedYear: data.selectedYear,

    optionSectors: data.sectors,
    selectedSector: ""
  };
};

// Set map data
export const filterMapData = (data) => {
  return {
    type: FILTER_STUDENT_MAP_DATA,
    loading: false,
    colors: data.colors,
    legend_keys: data.legend_keys,
    data: data.data,
    tableData: data.tableData,
    tableDisplayFilters: {
      selectedTypeMeasure: data.selectedTypeMeasure,
      selectedSpecificMeasure: data.selectedSpecificMeasure, 
      selectedSector: data.selectedSector},
    statsState: data.statsState,
    statsNation: data.statsNation,
    geoLevel:data.geoLevels,
    year: data.year,
    selectedGeoLevel: data.selectedGeoLevel,
    selectedYear: data.selectedYear
  };
};

// filter table data
export const filterTable = (e) => {
  let area = e.features[0].properties[geoNameMapping[e.features[0].layer.metadata.geoLevel]+"_NAME21"];
  // let area = e.features[0].properties["SA2_NAME21"];
  let state = e.features[0].properties["STE_NAME21"];
  // console.log("filterTable:" + area + " state: "+ state);
  return {
    type: SET_TABLE_DATA,
    selectedArea: area,
    selectedState: state
  };
};

// request data
export const requestJobs = () => async dispatch => {
  var req = {
    "initRequest":1
  };
  dispatch(setProgressBar(true));
  const {data} = await axios.post("/api/tech/data", req);
  dispatch(setMapData(data));   
};

export const updateGeoLevel=(value) => {
  return {
    type: UPDATE_GEO_LEVEL,
    selectedGeoLevel: value
  };
};

export const updateYear=(value) => {
  return {
    type: UPDATE_YEAR,
    selectedYear: value
  };
};

export const updateTypeMeasure=(value) => {
  return {
    type: UPDATE_TYPE_MEASURE,
    selectedTypeMeasure: value
  };
};

export const updateSpecificMeasure=(value) => {
  return {
    type: UPDATE_SPECIFIC_MEASURE,
    selectedSpecificMeasure: value
  };
};

export const updateSector=(value) => {
  return {
    type: UPDATE_SECTOR,
    selectedSector: value
  };
};



// Filter map by years and themes
export const filterMap = (specificMeasure, geoLevel, year) => async dispatch => {
  var req = {
    "specificMeasure":specificMeasure, 
    "geoLevel": geoLevel === null ? undefined : geoLevel,
    "year": year === null ? undefined : year
  };
  dispatch(setProgressBar(true));
  const {data} = await axios.post("/api/tech/data", req);
  dispatch(filterMapData(data));    
};

export const updateOptionSpecificMeasures = (measure) => {
  return {
    type: UPDATE_OPTION_SPECIFIC_MEASURES,
    selectedTypeMeasure: measure
  };
}
