import * as React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";

const DataTable = (props) => {
  const { tableDisplayData, tableDisplayFilters } = props;
  let displayLabel = "";
  switch (tableDisplayFilters.selectedTypeMeasure) { 
    case "Workers": 
      if (tableDisplayFilters.selectedSpecificMeasure === "Indigenous graduates"){
        displayLabel= "(number of VET and University graduates between 2016 and 2020)";
      }else{
        displayLabel= "(2021 Census Employment data)";
      }
      break;
    case "Number of job ads":
      displayLabel= "(number of job ads captured by Adzuna Australia between 2016 and 2020)";
      break;
    // case "Number of job ads per thousand workers":
    //   displayLabel= "(ratio of job ads per 1000 workers)";
    //   break;
    default:
      displayLabel = "";
  }
  let table_label = tableDisplayFilters.selectedSpecificMeasure + " "+ displayLabel;
  let sector_label = tableDisplayFilters.selectedSector.length === 0? "ALL" : tableDisplayFilters.selectedSector;

  return (
    <div className="row">
      <div className="row" style={{ paddingLeft: "20px", marginBottom: "0px"}}>
        <p>Data Table: <i>{table_label}</i></p>
        <ul id="filter-ul">
          <li>Industry Sector: {sector_label}</li>
          {/* <li>Education Level: {education_label}</li>
          <li>Broad Field of Education: {foe_label}</li>
          <li>Narrow Field of Education: {narrow_foe_label}</li> */}
        </ul>
      </div>
      <div  style={{height: "35vh", width: "95%", overflow: 'auto'}}> 
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 200 }} size="small" aria-label="a dense table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "25%", fontWeight: 'bold' }}></TableCell>
                <TableCell align="right" style={{ width: "25%", fontWeight: 'bold' }} >{tableDisplayData.selectedArea}</TableCell>
                <TableCell align="right" style={{ width: "25%", fontWeight: 'bold' }} >{tableDisplayData.selectedState}</TableCell>
                <TableCell align="right" style={{ width: "25%", fontWeight: 'bold' }} >Australia</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableDisplayData.displayData.map((row) => (
                <TableRow
                  key={row.var_name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell style={{ width: "25%" }} component="th" scope="row">
                    {row.var_name}
                  </TableCell>
                  <TableCell align="right" style={{ width: "25%" }} >{row.area_count}</TableCell>
                  <TableCell align="right" style={{ width: "25%" }}>{row.state_count}</TableCell>
                  <TableCell align="right" style={{ width: "25%" }}>{row.nation_count}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

function mapStateToPropsTableData(state) {
  return {
    selectedSpecificMeasure: state.student.selectedSpecificMeasure,
    tableDisplayData: state.student.tableDisplayData,
    tableData: state.student.tableData,
    tableDisplayFilters: state.student.tableDisplayFilters
  };
}

const ConnectedDataTable = connect(mapStateToPropsTableData)(DataTable);

export default ConnectedDataTable;
